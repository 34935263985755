<template>
    <div>
        <div class="settle-list">
            <el-table :data="list" style="width: 100%">
                <el-table-column label="结算时间" show-overflow-tooltip minWidth="150">
                    <template slot-scope="scope">
                        <p>{{ scope.row.date }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="订单来源" show-overflow-tooltip minWidth="150">
                    <template slot-scope="scope">
                        <p>{{ scope.row.bizType | formatBizType }}</p>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>总金额</span>
                            <settle-tooltip>
                                每月10日系统会结算上个自然月所产生的可结算费用，包括已结算佣金和已结算奖励金额。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.totalAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>佣金金额</span>
                            <settle-tooltip>
                                推广的商品交易成功带来的佣金金额。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.commissionAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>出单活动服务费</span>
                            <settle-tooltip>
                                参与出单奖励活动的订单交易成功带来的额外奖励。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.rewardAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>拉新活动服务费</span>
                            <settle-tooltip>
                                拉新奖励活动期间有效拉新带来的额外奖励。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.newerAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>邀请会员活动服务费</span>
                            <settle-tooltip>
                                邀请会员奖励活动期间邀请有效会员带来的额外奖励。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.inviteVipAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>拉新活动服务费</span>
                            <settle-tooltip>
                                邀请新赚客活动期间邀新赚客出单带来的额外奖励。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.inviteZhuankeAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>销售额活动服务费</span>
                            <settle-tooltip> 销售额活动期间服务费 </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.gmvAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>引流活动服务费</span>
                            <settle-tooltip>
                                引流奖励活动期间有效引流带来的额外奖励。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.flowAmountTmall | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip minWidth="150">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>红包出单活动服务费</span>
                            <settle-tooltip>
                                红包出单奖励活动期间有效出单带来的额外奖励。
                            </settle-tooltip>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        &yen;{{ scope.row.redAmount | formatPrice }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right">
                    <template slot="header">
                        <div class="settle-table-header">
                            <span>操作</span>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="showExportOrdersPrompt(scope.row.id)"
                            style="display: block"
                        >
                            导出明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import formatPrice from '../../utils/format-price';
import Tooltip from '../settle/tooltip';

export default {
    props: ['list'],
    components: {
        'settle-tooltip': Tooltip
    },
    filters: {
        formatPrice
    },
    methods: {
        showExportOrdersPrompt(id) {
            this.$emit('showExportOrdersPrompt', id);
        }
    }
};
</script>

<style lang='scss' scoped>
.settle-list {
  margin-top: 30px;
  width: 100%;
  overflow: auto;
}
.settle-table-header {
  padding-left: 0;
  line-height: 1;
  cursor: pointer;
}
</style>


<template>
    <div class="export-email">
        <el-dialog
            title="驳回账单"
            :visible.sync="rejectVisible"
            :show-close="false"
        >
            <span>驳回表示您对本月账单有疑问，建议您先联系客服对账，确认实际账单与系统给出账单不符后再驳回，驳回后无法在线上提交提现申请，请谨慎驳回</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消驳回</el-button>
                <el-button type="primary" @click="handleConfirm">驳回账单</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['rejectVisible'],
    methods: {
        handleCancel() {
            this.$emit('closeReject');
        },
        handleConfirm() {
            this.$emit('coopMonth');
        }
    }
};     
</script>

<style lang="scss" scoped>


</style>
<template>
    <div class="settle">
        <div class="zk-main-title">
            <h2>结算中心</h2>
        </div>
        <div class="settle-header">
            <el-alert title="订单“预计待结算金额”为昨日数据仅供参考。实际数据会根据订单是否发生售后产生变化，以最终结算为准。"
                      type="warning"
                      show-icon
            />
            <div class="settle-money" v-if="zhuankeType === 0">
                <div class="settle-amount">
                    <p class="tip">
                        预估待结算金额
                        <settle-tooltip>
                            包括订单完成时间为昨日的推广佣金和活动奖励，本数据仅供参考，<br>
                            实际数据会根据订单是否发生售后产生变化，以最终结算为准。
                        </settle-tooltip>
                    </p>
                    <p class="money"><span class="symbol">&yen;</span>{{ toSettledAmount }}</p>
                </div>
                <div class="settle-amount">
                    <p class="tip">
                        可提现金额
                        <settle-tooltip>
                            已结算未提现部分金额，个人赚客可前往微信端“考拉赚客”公众号进行提现。
                        </settle-tooltip>
                    </p>
                    <p class="money"><span class="symbol">&yen;</span>{{ availableCashAmount }}</p>
                </div>
                <div class="settle-amount un">
                    <p class="tip">
                        累积已提现金额
                        <settle-tooltip>
                            累计已提现金额为所有历史已提现金额之和。
                        </settle-tooltip>
                    </p>
                    <p class="money"><span class="symbol">&yen;</span>{{ intoCashAmount }}</p>
                </div>
            </div>

            <div class="settle-money" v-if="zhuankeType === 1">
                <div class="settle-amount">
                    <p class="tip">
                        累计已结算金额
                        <settle-tooltip>
                            每月10日系统会结算上个自然月所产生的可结算费用，包括销售佣金、活动奖励金额。<br>
                            累计已结算金额为所有历史已结算数据之和。
                        </settle-tooltip>
                    </p>
                    <p class="money"><span class="symbol">&yen;</span>{{ totalSettledAmount }}</p>
                </div>
                <div class="settle-amount un">
                    <p class="tip">
                        预估待结算金额
                        <settle-tooltip>
                            包括订单完成时间为昨日的推广佣金和活动奖励，本数据仅供参考，<br>
                            实际数据会根据订单是否发生售后产生变化，以最终结算为准。
                        </settle-tooltip>
                    </p>
                    <p class="money"><span class="symbol">&yen;</span>{{ totalUnSettledAmount }}</p>
                </div>
            </div>
            <div class="settle-info" v-if="zhuankeType === 1">
                <a href="/help?key=cash" class="el-button el-button--primary"
                   target="_blank"
                >提现说明</a>
            </div>
            <div class="settle-info" v-if="zhuankeType === 0">
                <div class="el-button el-button--primary" @click="showPersonWithdraw">
                    提现说明
                </div>
            </div>
        </div>
        <div class="settle-wrapper">
            <div class="mm">
                <h3>结算记录</h3>
                <div class="mm" style="margin-left: 20px">
                    订单来源
                    <el-select style="margin-left: 10px" class="reward-middle1" v-model="bizType" placeholder="请选择"
                               @change="cg"
                    >
                        <el-option
                            label="全部（猫享与考拉）"
                            :value="-1"
                        />
                        <el-option
                            label="猫享自营"
                            :value="1"
                        />
                        <el-option
                            label="考拉海购"
                            :value="0"
                        />
                        <el-option
                            label="天猫商家"
                            :value="2"
                        />
                    </el-select>
                </div>
            </div>

            <div class="settle-list" v-loading="loading" v-if="zhuankeType === 1">
                <el-table :data="list" style="width: 100%;">
                    <el-table-column label="结算时间" show-overflow-tooltip minWidth="150">
                        <template slot-scope="scope">
                            <p>{{ scope.row.date }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单来源" show-overflow-tooltip minWidth="150">
                        <template slot-scope="scope">
                            <p>{{ scope.row.bizType | formatBizType }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>总金额</span>
                                <settle-tooltip>
                                    每月10日系统会结算上个自然月所产生的可结算费用，包括销售佣金、活动奖励金额。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.totalAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>佣金金额</span>
                                <settle-tooltip>
                                    推广的商品交易成功带来的佣金金额。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.commissionAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>出单活动服务费</span>
                                <settle-tooltip>
                                    参与出单奖励活动的订单交易成功带来的额外奖励。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.rewardAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>拉新活动服务费</span>
                                <settle-tooltip>
                                    拉新奖励活动期间有效拉新带来的额外奖励。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.newerAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>邀请会员活动服务费</span>
                                <settle-tooltip>
                                    邀请会员奖励活动期间邀请有效会员带来的额外奖励。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.inviteVipAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>销售额活动服务费</span>
                                <settle-tooltip>
                                    销售额活动期间活动服务费。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.gmvAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>引流活动服务费</span>
                                <settle-tooltip>
                                    引流奖励活动期间有效引流带来的额外奖励。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.flowAmountTmall | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip minWidth="150">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>红包出单活动服务费</span>
                                <settle-tooltip>
                                    红包出单奖励活动期间有效出单带来的额外奖励。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            &yen;{{ scope.row.redAmount | formatPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right">
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>操作</span>
                                <settle-tooltip>
                                    如果你对当月账单没有异议，请点击确认账单；如果你对当月账单有疑问，可先联系客服对账再确认。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <el-button type="text"
                                       @click="showExportOrdersPrompt(scope.row.id)"
                                       style="display: block"
                            >
                                导出订单
                            </el-button>
                            <el-button type="text" style="margin-left: 0"
                                       v-if="scope.row.confirmStatus === 0"
                                       @click="confirmBill(1,scope.row.id)"
                            >
                                确认订单
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <div class="settle-table-header">
                                <span>账单状态</span>
                                <settle-tooltip>
                                    2019年11月及之前的结算账单默认给您显示“已确认账单”，对这部分账单有疑问可联系客服。
                                </settle-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.statusDesc }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-loading="loading" v-if="zhuankeType === 0">
                <personalSettle :list="list" @showExportOrdersPrompt="showExportOrdersPrompt" />
            </div>
            <div class="settle-page" v-if="totalCount">
                <pagination @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageIndex"
                            :page-size="pageSize"
                            :total="totalCount"
                />
            </div>
        </div>

        <el-dialog
            title="提现说明"
            :visible.sync="personDialogVisible"
            width="40%"
            :show-close="false"
        >
            <p>每月10日结算上一个自然月产生的可结算佣金，可在结算记录中查看每月账单；可提现部分个人赚客可前往微信端“考拉赚客”公众号进行提现。</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="personDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>

        <export-email-dialog :visible.sync="visible"
                             @email-value="getExportEmail"
        />
        <confirmBillDialog
            :confirm-visible.sync="confirmVisible"
            @closeConfirm="closeConfirm"
            @coopMonthConfirm="coopMonthConfirm"
        />
        <rejectBillDialog
            :reject-visible="rejectVisible"
            @closeReject="closeReject"
            @coopMonth="coopMonthConfirm"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ExportEmailDialog from '../../components/settle/export-email';
import confirmBillDialog from '../../components/settle/confiom-bill';
import rejectBillDialog from '../../components/settle/reject-bill';
import Tooltip from '../../components/settle/tooltip';
import personalSettle from '../../components/settle/personalSettle.vue';
import Pagination from '../../components/common/pagination';
import formatPrice from '../../utils/format-price';

// @todo tooltip
export default {
    data() {
        return {
            bizType: -1,
            loading: false,
            totalSettledAmount: 0,
            totalUnSettledAmount: 0,
            list: [],
            pageIndex: 1,
            pageSize: 40,
            totalCount: 0,
            visible: false,
            currentOrderId: null,
            confirmVisible: false,
            rejectVisible: false,
            rejectBillDialog: false,
            confirmStatus: 0,
            billId: '',
            personDialogVisible: false,
            toSettledAmount: 0,
            availableCashAmount: 0,
            intoCashAmount: 0
        };
    },

    computed: {
        ...mapState([
            'zhuankeType'
        ])
    },

    components: {
        'export-email-dialog': ExportEmailDialog,
        'settle-tooltip': Tooltip,
        pagination: Pagination,
        confirmBillDialog,
        rejectBillDialog,
        personalSettle
    },

    filters: {
        formatPrice
    },

    created() {

        if(this.zhuankeType === 1) {
            this.getTotalAmount();
            this.getMonthList();
        } else {
            this.getPersonTotalAmount();
            this.getPersonMonthlist();
        }

    },

    methods: {
        cg() {
            if(this.zhuankeType === 1) {
                this.getMonthList();
            } else {
                this.getPersonMonthlist();
            }
        },
        async getTotalAmount() {
            const totalAmount = await this.$request({
                url: '/api/settle/coop/total'
            });
            this.totalSettledAmount = totalAmount.totalSettledAmount || 0;
            this.totalUnSettledAmount = totalAmount.totalUnSettledAmount || 0;
        },
        async getPersonTotalAmount() {
            const totalAmount = await this.$request({
                url: '/api/settle/coop/personTotalAmount'
            });
            this.toSettledAmount = totalAmount.toSettledAmount || 0;
            this.availableCashAmount = totalAmount.availableCashAmount || 0;
            this.intoCashAmount = totalAmount.intoCashAmount || 0;
        },
        async getMonthList() {
            try {
                this.loading = true;
                const data = await this.$request({
                    url: '/api/settle/coop/monthlist',
                    params: {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        bizType: this.bizType
                    }
                });
                if(data.list && data.list.length > 0) {
                    data.list.forEach((value) => {
                        if(value. confirmStatus === 0) {
                            Object.assign(value, {statusDesc: '待确认'});
                        }
                        if(value.confirmStatus === 1) {
                            Object.assign(value, {statusDesc: '已确认账单'});
                        }
                        if(value.confirmStatus === 2) {
                            Object.assign(value, {statusDesc: '已驳回账单'});
                        }
                        if(value.confirmStatus === 3) {
                            Object.assign(value, {statusDesc: '已确认账单'});
                        }
                    });
                }
                this.list = data.list || [];
                this.totalCount = data.totalNum || 0;
            } finally {
                this.loading = false;
            }
        },

        async getPersonMonthlist() {
            const params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                bizType: this.bizType
            };
            try {
                this.loading = true;
                const data = await this.$request({
                    url: '/api/settle/coop/personMonthlist',
                    params
                });
                this.list = data.list || [];
                this.totalCount = data.totalNum || 0;
            } finally {
                this.loading = false;
            }
        },

        closeConfirm() {
            this.confirmVisible = false;
        },
        closeReject() {
            this.rejectVisible =false;
        },
        confirmBill(status, id) {
            this.confirmVisible = true;
            this.confirmStatus = status;
            this.billId = id;
        },
        rejectBill(status, id) {
            this.rejectVisible = true;
            this.confirmStatus = status;
            this.billId = id;
        },
        coopMonthConfirm() {
            this.rejectVisible =false;
            this.confirmVisible = false;
            this.$request({
                url: '/api/settle/coopMonthConfirm',
                params: {
                    billId: this.billId,
                    confirmStatus: this.confirmStatus
                }
            }).then(() => {
                this.pageIndex = 1;
                this.getMonthList();
            });
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.pageIndex = 1;
            if(this.zhuankeType === 1) {
                this.getMonthList();
            } else {
                this.getPersonMonthlist();
            }
        },

        handleCurrentChange(p) {
            this.pageIndex = p;
            if(this.zhuankeType === 1) {
                this.getMonthList();
            } else {
                this.getPersonMonthlist();
            }
        },

        showExportOrdersPrompt(id) {
            this.visible = true;
            this.currentOrderId = id;
        },

        getExportEmail(email) {
            this.exportOrders(this.currentOrderId, email);
        },

        exportOrders(id, email) {
            if(this.zhuankeType === 1) {
                this.$request({
                    url: '/api/settle/coop/export',
                    method: 'post',
                    data: {
                        id,
                        email
                    },
                    origin: true
                }).then((data) => {
                    setTimeout(() => {
                        if(data.code === 200) {
                            this.showExportOftenAlert();
                            return;
                        }
                        this.showExportErrorAlert(data.desc || '');
                    }, 300);
                }).catch(() => {
                    this.showExportErrorAlert();
                });
            }
            if(this.zhuankeType === 0) {
                this.$request({
                    url: '/api/settle/coop/personExport',
                    method: 'post',
                    data: {
                        id,
                        email
                    },
                    origin: true
                }).then((data) => {
                    setTimeout(() => {
                        if(data.code === 200) {
                            this.showExportOftenAlert();
                            return;
                        }
                        this.showExportErrorAlert(data.desc || '');
                    }, 300);
                }).catch(() => {
                    this.showExportErrorAlert();
                });
            }

        },

        showExportErrorAlert(errText) {
            const err = errText ? errText : '导出失败';
            this.$alert(err, '提示', {
                confirmButtonText: '确定'
            });
        },

        showExportOftenAlert() {
            this.$alert('您下载的文件正在生成，请不要重复点击，文件生成后，将会以邮件形式发送给您', '提示', {
                confirmButtonText: '确定'
            });
        },
        showPersonWithdraw() {
            this.personDialogVisible = true;
        }
    }
};
</script>

<style lang="scss" scoped>
    .settle {
        min-height: 100%;

        &-header {
            position: relative;
            background: #fff;
            padding: 16px 16px 32px;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            .settle-money {
                margin-top: 20px;

                .settle-amount {
                    padding-right: 20px;
                    padding-left: 20px;
                    display: inline-block;
                    border-right: 1px solid #ccc;
                    &.un {
                        border-right: 0;
                    }
                    .tip {
                        font-size: 14px;
                    }
                    .money {
                        margin-top: 10px;
                        font-size: 32px;
                        font-weight: bold;
                        color: red;
                        text-align: center;
                        .symbol {
                            font-size: 24px;
                        }
                    }
                }
            }
            .settle-info {
                position: absolute;
                right: 16px;
                bottom: 16px;
            }
        }
        &-info {
            margin-top: 20px;
        }
        &-wrapper {
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            padding: 16px;
            background: #fff;
            h3 {
                margin-bottom: 10px;
            }
            margin-top: 20px;

            .settle-list {
                margin-top: 30px;
            }
        }
        &-page {
            margin-top: 20px;
            text-align: right;
        }
    }
    .settle-table-header {
        padding-left: 0;
        line-height: 1;
        cursor: pointer;
    }

    .mm {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
</style>

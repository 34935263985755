
<template>
    <div class="export-email">
        <el-dialog
            title="确认账单"
            :visible.sync="confirmVisible"
            :show-close="false"
        >
            <span>账单一经确认，即表示您对本月账单无异议，确认后无法更改</span>
            <br><br>
            <span style="font-size: 12px;">注：如果你对账单有疑问，可先联系客服对账再确认</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消确认</el-button>
                <el-button type="primary" @click="handleConfirm">确认账单</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['confirmVisible'],
    methods: {
        handleCancel() {
            this.$emit('closeConfirm');
        },
        handleConfirm() {
            this.$emit('coopMonthConfirm');
        }
    }
};
</script>

<style lang="scss" scoped>


</style>
